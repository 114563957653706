import { React, useEffect, useRef, useState } from "react";
import { Image, Alert } from "react-bootstrap";
import DialogModal from "../components/modal/DialogModal";
import axios from "axios";
import { API_URL } from "../constants";
// import DocBoxImg from "../assets/demo-factura.jpg";
import topImage from "../assets/top_image.png";
import FacturaImg from "../assets/factura.svg";
import {ReactComponent as CheckCircle} from "../assets/check-circle.svg";
import {
  default as PaynetEcomAPI,
  PaynetRequest,
  PaynetResult,
  PaynetCode,
} from "../utils/PaynetApi";
import { Link } from "react-router-dom";

// const demo = {
//   "CODID": "200000131",
//   "NP": "Cucu Nicolae",
//   "SERVICES": [
//     {
//       "CODSERV": "1",
//       "SUMA": 83.8,
//       "TIPSERV": "Serv. de administrare",
//       "CONTORID": null,
//       "CONTORVLP": null,
//       "CONTORVLI": null,
//       "CONTOROR": null
//     },
//     {
//       "CODSERV": "7",
//       "SUMA": 478.79,
//       "TIPSERV": "Energia electrica",
//       "CONTORID": "EE131-2",
//       "CONTORVLP": 1499,
//       "CONTORVLI": null,
//       "CONTOROR": 1
//     },
//     {
//       "CODSERV": "19",
//       "SUMA": 9.64,
//       "TIPSERV": "Ener. electr. lift",
//       "CONTORID": null,
//       "CONTORVLP": null,
//       "CONTORVLI": null,
//       "CONTOROR": null
//     },
//     {
//       "CODSERV": "29",
//       "SUMA": 9.39,
//       "TIPSERV": "Ener. electr. comuna",
//       "CONTORID": null,
//       "CONTORVLP": null,
//       "CONTORVLI": null,
//       "CONTOROR": null
//     },
//     {
//       "CODSERV": "8",
//       "SUMA": 19.27,
//       "TIPSERV": "Transportare deseuri",
//       "CONTORID": null,
//       "CONTORVLP": null,
//       "CONTORVLI": null,
//       "CONTOROR": null
//     },
//     {
//       "CODSERV": "12",
//       "SUMA": -1.09,
//       "TIPSERV": "Cheltuieli comune",
//       "CONTORID": null,
//       "CONTORVLP": null,
//       "CONTORVLI": null,
//       "CONTOROR": null
//     },
//     {
//       "CODSERV": "11",
//       "SUMA": 15,
//       "TIPSERV": "Supraveghere video",
//       "CONTORID": null,
//       "CONTORVLP": null,
//       "CONTORVLI": null,
//       "CONTOROR": null
//     },
//     {
//       "CODSERV": "10",
//       "SUMA": 5,
//       "TIPSERV": "Deservire interfon",
//       "CONTORID": null,
//       "CONTORVLP": null,
//       "CONTORVLI": null,
//       "CONTOROR": null
//     },
//     {
//       "CODSERV": "9",
//       "SUMA": 41.48,
//       "TIPSERV": "Deservire Lift",
//       "CONTORID": null,
//       "CONTORVLP": null,
//       "CONTORVLI": null,
//       "CONTOROR": null
//     },
//     {
//       "CODSERV": "5",
//       "SUMA": 104.79,
//       "TIPSERV": "Apa potabila",
//       "CONTORID": "160239",
//       "CONTORVLP": 231,
//       "CONTORVLI": null,
//       "CONTOROR": 1
//     }
//   ]
// }

const MERCHANT_CODE = "295847";
const MERCHANT_SEC_KEY = "85D0AB0F-EB02-4ACE-BE3E-07EE34AB92C8";
const MERCHANT_USER = "532342";
const MERCHANT_USER_PASS = "tfKwgiRB";

const MERCHANT_MODE = false;


const FacturaTableInfo = ({
  receipt,
  i,
  getTotalSum,
  reply,
  index,
  inputRefs,
}) => {
  const [consume, setConsume] = useState(0);
  if(!receipt.CONTORVLI) receipt.CONTORVLI = receipt.CONTORVLP;
  const handleValidIndice = (e) => {
    if (e.target.value < receipt.CONTORVLP) {
      e.target.value = parseFloat(receipt.CONTORVLP);
      receipt.CONTORVLI = parseFloat(receipt.CONTORVLP);
      e.currentTarget.classList.add("animate-input-border");
    } else {
      receipt.CONTORVLI = parseFloat(e.target.value);
    }
    let maxDiff = e.target.value - receipt.CONTORVLP;
    if (receipt.CODSERV === "7" && maxDiff > 500) {
      e.target.value = parseFloat(receipt.CONTORVLP) + 500;
      receipt.CONTORVLI = parseFloat(e.target.value);
      e.currentTarget.classList.add("animate-input-border");
    }
    if (receipt.CODSERV === "5" && maxDiff > 100) {
      e.target.value = parseFloat(receipt.CONTORVLP) + 100;
      receipt.CONTORVLI = parseFloat(e.target.value);
      e.currentTarget.classList.add("animate-input-border");
    }
    calculateConsume();
  };
  const removeClass = (e) => {
    e.currentTarget.classList.remove("animate-input-border");
  };
  const handleChange = (e) => {
    let val = (e.target.value && e.target.value.length) ? e.target.value : 0;
    if(parseFloat(val) < 0) {
      val = 0;
    }
    receipt.CONTORVLI = parseFloat(val);
    calculateConsume();
  };
  const calculateConsume = () => {
    setConsume(
      (receipt.CONTORVLI ? receipt.CONTORVLI : receipt.CONTORVLP) -
        receipt.CONTORVLP
    );
  };
  const getMeasure = () => {
    if (receipt.CODSERV === "7") {
      return "kW/h";
    } else if (receipt.CODSERV === "5") {
      return "m3";
    }
  };
  useEffect(() => {
    console.log("state");
    if(receipt) calculateConsume();
  }, [receipt]);
  return (
    <>
      <tr className="show-special-thead special-thead-border">
        <td colSpan={3} className="td-border-style">
          {receipt.TIPSERV}
        </td>
        <td style={{ fontSize: "10px" }}>
          {receipt.CONTORVLP ? (
            <>
              Consum: <b>{consume}</b> {getMeasure()}
            </>
          ) : null}
        </td>
      </tr>
      <tr key={i}>
        <td className="hide-special-thead">{receipt.TIPSERV}</td>
        <td>
          <b>{receipt.CONTORID ? receipt.CONTORID : "--.--"}</b>
        </td>
        {/* <td>
              <b>
                {receipt.date_by ? receipt.date_by : "--.--"}
              </b>
            </td> */}
        <td>
          <b>{receipt.CONTORVLP ? receipt.CONTORVLP : "--.--"}</b>
        </td>
        <td className="td-border-style">
          {receipt.SUMA !== null &&
          receipt.SUMA !== undefined &&
          typeof receipt.SUMA === "number" ? (
            <input
              type="number"
              name="SUMA"
              onKeyPress={(event) => {
                if (event?.key === '-' || event?.key === '+') {
                  event.preventDefault();
                }
              }}
              onChange={(e) => {
                let val = (e.target.value && e.target.value.length) ? e.target.value : 0;
                if(parseFloat(val) < 0) {
                  val = 0;
                }
                receipt.SUMA = parseFloat(val);
                getTotalSum();
              }}
              defaultValue={receipt.SUMA.toFixed(2)}
              className="contor-input"
            />
          ) : (
            <b>--.--</b>
          )}
        </td>
        <td className="td-border-left-style">
          {receipt.CONTORVLP ? (
            <input
              type="number"
              ref={(el) => (inputRefs.current[i] = el)}
              name="CONTORVLP"
              autoFocus={reply === "agreedToIndice" && i === index}
              min={receipt.CONTORVLP}
              onChange={handleChange}
              onFocus={removeClass}
              onBlur={handleValidIndice}
              defaultValue={
                receipt.CONTORVLI ? receipt.CONTORVLI : receipt.CONTORVLP
              }
              className="contor-input"
            />
          ) : (
            <b>--.--</b>
          )}
        </td>
        {
          <td className={"hide-special-thead"}>
            {receipt.CONTORVLP ? (
              <>
                <b>{consume}</b> {getMeasure()}
              </>
            ) : null}
          </td>
        }
      </tr>
    </>
  );
};

const api = new PaynetEcomAPI(
  MERCHANT_CODE,
  MERCHANT_SEC_KEY,
  MERCHANT_USER,
  MERCHANT_USER_PASS
);
const prequest = new PaynetRequest();

function Factura() {
  const [codeNumber, setCodeNumber] = useState();
  const [isCodeCorrect, setIsCodeCorrect] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(false);
  const [totalSum, setTotalSum] = useState(0);
  const [payInfo, setPayInfo] = useState(null);
  const [show, setShow] = useState(false);
  const [canProceed, setCanProceed] = useState(false);
  const [yesText, setYesText] = useState("");
  const [noText, setNoText] = useState("");
  const [question, setQuestion] = useState("");
  const [isIndiceDisabled, setIsIndiceDisabled] = useState(false);
  const [fnToRun, setFnToRun] = useState("");
  const [reply, setReply] = useState("");
  const [focusIndex, setFocusIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [paymentInProcess, setPaymentInProcess] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [externalId, setexternalId] = useState(Math.round(Date.now()))
 // const externalId = Math.round(Date.now());

  const refForm = useRef();
  const checkRef = useRef();
  const checkRef2 = useRef();
  const labelRef = useRef();
  const labelRef2 = useRef();
  const inputRefs = useRef([]);

  var FormCreate = api.FormCreate2;

  const findIndiceIndex = () => {
    for (let i = 0; i < userInfo.SERVICES.length; i++) {
      const element = userInfo.SERVICES[i];
      if (element.CONTORVLP) {
        setFocusIndex(i);
        break;
      }
    }
  };

  async function checkIfProcessing(id) {
    try {
      const res = await axios.get(API_URL + "user/processing/" + id);
      if (res.data) {
        setPaymentInProcess(res.data.processing);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const checkTerms = (ev) => {
    setCanProceed(ev.target.checked);
  };

  const getFactura = async () => {
    // setUserInfo(demo);
    // setIsCodeCorrect(true);
    // return;

    if (codeNumber.length !== 9) {
      setError(true);
      return;
    }
    try {
      setUserInfo(null);
      const res = await axios.get(API_URL + "user/info/" + codeNumber);

      if (res.data) {
        setUserInfo(res.data);
        setIsCodeCorrect(true);
        if (
          res.data.SERVICES.filter((item) => item.CONTORVLP).every(
            (item) => item.CONTORVLI && item.CONTORVLI >= item.CONTORVLP
          )
        ) {
          setIsIndiceDisabled(true);
        } else {
          setIsIndiceDisabled(false);
        }
      } else {
        setError(true);
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onChangeHandler = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    setCodeNumber(value);
  };
  const goBack = () => {
    setIsCodeCorrect(false);
  };
  const openPaymentModal = (e) => {
    e.preventDefault();
    checkRef.current.classList.remove("animate-input-checkbox");
    checkRef2.current.classList.remove("animate-input-checkbox");
    labelRef.current.classList.remove("animate-input-label");
    labelRef2.current.classList.remove("animate-input-label");

    if (canProceed) {
      setShow(true);
      setYesText("Vreau sa indic indicii");
      setNoText("Vreau doar sa achit");
      setQuestion("Doriți să specificați indicii?");
      setFnToRun("pay");
    } else {
      // console.log("checkRef.current", labelRef.current);
      if (checkRef.current && labelRef.current && labelRef2.current && checkRef2.current) {
        checkRef.current.focus();
        checkRef2.current.focus();
        checkRef.current.classList.add("animate-input-checkbox");
        checkRef2.current.classList.add("animate-input-checkbox");
        labelRef.current.classList.add("animate-input-label");
        labelRef2.current.classList.add("animate-input-label");
      }
    }
  };
  const openIndiceModal = () => {
    setShow(true);
    setYesText("Vreau sa achit acum");
    setNoText("Nu, multumesc");
    setQuestion("Doriți să faceți o plată?");
    setFnToRun("indice");
  };

  useEffect(() => {
    if (reply === "agreedToPay") {
      console.log("ran here");
      if (checkRef.current && labelRef.current && labelRef2.current && checkRef2.current) {
        checkRef.current.focus();
        checkRef2.current.focus();
        checkRef.current.classList.add("animate-input-checkbox");
        checkRef2.current.classList.add("animate-input-checkbox");
        labelRef.current.classList.add("animate-input-labelooooo");
        labelRef2.current.classList.add("animate-input-labelooooo");
      }
    }
  }, [reply]);

  const onSave = async () => {

    let userData = userInfo.SERVICES.filter((service) => service.CONTORVLI).map(
      (service) => {
        return {
          codId: userInfo.CODID,
          contorId: service.CONTORID,
          indiceActuali: service.CONTORVLI,
        };
      }
    );
    try {
      const res = await axios.post(API_URL + "user/saveIndici", userData);
      //console.log(res);

      if (res.data) {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false)
        }, 10000)
        getFactura()
      };
    } catch (error) {
      console.log(error);
    }
  };
  const onPay = async () => {
    let userData = userInfo.SERVICES.filter((service) => service.SUMA > 0).map(
      (service) => {
        return {
          CODID: userInfo.CODID,
          CODSERV: service.CODSERV,
          SUMA: service.SUMA,
          USERINFO: userInfo.NP,
          PAY_TYPE: payInfo ? payInfo.PAY_TYPE : "",
          PAY_DATE: payInfo ? payInfo.PAY_DATE : new Date(),
        };
      }
    );
    try {
      const res = await axios.post(API_URL + "user/savePayments", {
        externalId: externalId,
        inputArray: userData,
      });
      //console.log(res);
      if (res.data) {
        //  window.location.href = api.PAYNET_BASE_UI_URL;
        // getFactura();
       // console.log(refForm.current);
        refForm.current && refForm.current.submit();
        return res.data;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getTotalSum = () => {
    let totalSum = 0;
    for (let i = 0; i < userInfo.SERVICES.length; i++) {
      const element = userInfo.SERVICES[i];
      totalSum += element.SUMA;
    }
   // console.log(totalSum);
    setTotalSum(totalSum.toFixed(2));
    getForm();
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      getFactura();
    }
  };

  useEffect(() => {
    if (userInfo) {
      getTotalSum();
      getForm();
      findIndiceIndex();
   //   console.log({ USERINFOSERVICES: userInfo.SERVICES });
      if (userInfo.SERVICES[0].PAYMENT_ID) {
        checkIfProcessing(userInfo.SERVICES[0].PAYMENT_ID);
      }
    }
  }, [userInfo]);

  const specFunc = () => {
    setShow(false);
    // fnToRun === "pay" ? onPay() : fnToRun === "indice" ? onSave() : null;
    // fnToRun === "pay"
    //   ? setReply("agreedToIndice")
    //   : fnToRun === "indice"
    //   ? setReply("agreedToPay")
    //   : null;

    if (fnToRun === "pay") {
      console.log("ran here1");
      // onPay();
      setReply("agreedToIndice");
      if (inputRefs.current[focusIndex]) {
        inputRefs.current[focusIndex].focus();
      }
    } else if (fnToRun === "indice") {
      onSave();
      setReply("agreedToPay");
    }
  };

  const specFuncTwo = () => {
    setShow(false);

    if (fnToRun === "pay") {
      console.log("ran here2");
      onPay();
      setReply("declinedToIndice");
    } else if (fnToRun === "indice") {
      onSave();
      setReply("declinedToPay");
    }
  };

  const getForm = () => {
    // setTimeout(() => {
    //   setLoading(false);
    // }, 1000);

    if (userInfo) {
      let id = Math.round(Date.now());
          setexternalId(id);

      prequest.ExternalID = id;
      prequest.LinkSuccess = `https://profadmin.mindscape.xyz/factura/ok?id=${prequest.ExternalID}`;
      prequest.LinkCancel = `https://profadmin.mindscape.xyz/factura/cancel?id=${prequest.ExternalID}`;
      prequest.Lang = "ro";
      prequest.Products = userInfo.SERVICES.filter(
        (service) => service.SUMA > 0
      ).map((service, i) => {
        return {
          LineNo: i + 1,
          CODID: userInfo.CODID,
          CODSERV: service.CODSERV,
          UnitPrice: service.SUMA * 100,
          Quantity: 1,
          Name: service.TIPSERV,
        };
      });
      prequest.Amount = totalSum;
      prequest.Service = {
        Name: "ProfAdmin",
        Description: "Achitare factura",
        Amount: prequest.Amount,
        Products: prequest.Products,
      };
      prequest.Customer = {
        Code: codeNumber,
        Address: "profadmin.md",
        Name: userInfo.NP,
      };
      FormCreate = api.FormCreate2({
        passRef: refForm,
        api: api,
        pRequest: prequest,
        onSubmit: openPaymentModal,
        // disabled: !canProceed,
      });
      //console.log(prequest);
    }
  };

  // getForm();
  const submit = async (e) => {
   // console.log("ran here3");
    await onPay();
  };
  //
  // useEffect(() => {
  //   console.log(prequest);
  // }, [prequest]);

  return (
    <div className="factura-pages">
      <section className="hero-wrapper hero-2">
        {/* <div className="hero-slider-2 owl-carousel owl-theme">
          <div className="single-slide slide-1">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div
                    className="hero-contents bg-cover"
                    style={{ backgroundImage: "url(" + topImage + ")" }}
                  >
                    <div
                      className="slide-title wow fadeInLeft animated"
                      data-wow-duration="1.3s"
                    >
                      Soluția de succes{" "}
                      <span className={"aa"}>pentru condominiul tău</span>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </section>
      <section className="pay-online-section ">
        <div className="container">
          {!isCodeCorrect && (
            <div className="row position-relative">
              <div className="col-xl-12">
                <div
                  className={`block-contents text-center ${
                    isCodeCorrect && "d-grid-extra"
                  }`}
                >
                  <div className="section-title about-title">
                    <h2>Achită online</h2>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isCodeCorrect ? (
            <div className="d-flex flex-column">
              <div>
                <table className="factura-table pt-3">
                  <thead>
                    <tr className="show-sm-display">
                      <th colSpan={4}>
                        <div className="d-flex mb-4 back-btn-block">
                          <button onClick={goBack} className="back-btn">
                            <i className="fa-sharp fa-solid fa-angle-left"></i>
                          </button>
                          <h2>Achită online</h2>
                        </div>
                      </th>
                    </tr>
                    <tr className="hide-sm-display">
                      <th colSpan={4} className="td-border-style">
                        {/* <div className="position-absolute back-btn-container">
                          <button onClick={goBack} className="back-btn">
                            <i className="fa-sharp fa-solid fa-angle-left"></i>
                          </button>
                        </div>
                        <div className="section-title about-title text-center">
                          <h2>Achita online</h2>
                        </div> */}
                        <div className="d-flex mb-4 back-btn-block btn-container">
                          <button onClick={goBack} className="back-btn">
                            <i className="fa-sharp fa-solid fa-angle-left"></i>
                          </button>
                          <div className="section-title about-title text-center">
                            <h2>Achită online</h2>
                          </div>
                        </div>
                      </th>
                      <th className="max-w-300">
                        <div className="section-title about-title text-center">
                          <h2>Prezintă Indicii</h2>
                        </div>
                      </th>
                    </tr>
                    <tr className="show-sm-display">
                      <td colSpan={4}>
                        <div className="d-flex flex-column">
                          <span className="text-blue mb-1">
                            Proprietar: <b>{userInfo && userInfo.NP}</b>
                          </span>
                        </div>
                        <div className="d-flex justify-content-start">
                          <span className="text-blue mb-4">
                            Codul Individual:{" "}
                            <b>{userInfo && userInfo.CODID}</b>
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr className="hide-sm-display">
                      <td colSpan={4} className="td-border-style">
                        <div className="d-flex flex-column">
                          <span className="text-blue mt-3 mb-4">
                            Proprietar: <b>{userInfo && userInfo.NP}</b>
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-end">
                          <span className="text-blue mt-3 mb-4">
                            Codul Individual:{" "}
                            <b>{userInfo && userInfo.CODID}</b>
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr className="special-tr">
                      <th className="hide-special-thead"></th>
                      <th>Numărul contorului</th>
                      {/* <th>Valabil pana la</th> */}
                      <th>Indicii precedenti</th>
                      <th className="td-border-style">
                        <span>Suma (Lei)</span>
                      </th>
                      <th>Indicii actuali</th>
                      <th className="hide-special-thead">Consum</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userInfo &&
                      userInfo.SERVICES.map((receipt, i) => {
                        return (
                          <FacturaTableInfo
                            key={i}
                            receipt={receipt}
                            i={i}
                            getTotalSum={getTotalSum}
                            reply={reply}
                            index={focusIndex}
                            getForm={getForm}
                            inputRefs={inputRefs}
                          />
                        );
                      })}
                    <tr>
                      <td className="hide-special-thead" colSpan={2}>
                        {/* {api.Version()} */}
                        {!paymentInProcess ? (
                          <>
                            <div className="d-flex align-items-center gap-2">
                              <input
                                type="checkbox"
                                id="terms"
                                ref={checkRef}
                                checked={canProceed}
                                onChange={checkTerms}
                              />
                              <label ref={labelRef} htmlFor="terms">
                                Am citit și sunt de acord cu{" "}
                                <Link
                                  to="/terms"
                                  target={'_blank'}
                                  className="go-to-factura-link"
                                >
                                  termenii și condițiile
                                </Link>
                                .
                              </label>
                            </div>
                            {prequest.Products.length ? (
                              <FormCreate
                                passRef={refForm}
                                api={api}
                                pRequest={prequest}
                                onSubmit={openPaymentModal}
                                // disabled={!canProceed}
                              />
                            ) : null}
                          </>
                        ) : (
                          <div className="w-100">
                            <span className="processing-text">
                              Există o tranzacție în procesare, vă rugăm să
                              reveniți mai tîrziu
                            </span>
                          </div>
                        )}
                        {/* <button
                          onClick={openPaymentModal}
                          className="small-reverse-ident-btn"
                          type="submit"
                        >
                          Achita Online
                        </button> */}
                      </td>
                      <td className="show-special-thead">
                        {/* <button
                          onClick={onPay}
                          className="small-reverse-ident-btn"
                          type="submit"
                        >
                          Achita Online
                        </button> */}
                      </td>
                      <td>
                        <b>Total:</b>
                      </td>
                      <td className="td-border-style">
                        <span>
                          <b>{totalSum} (Lei)</b>
                        </span>
                      </td>
                      <td>
                        <button
                          onClick={openIndiceModal}
                          className="small-ident-btn"
                          type="submit"
                          disabled={isIndiceDisabled}
                        >
                          Salvare
                        </button>
                      </td>
                    </tr>
                    <tr className="show-special-thead">
                      <td colSpan={6}>
                        {!paymentInProcess ? (
                            <>
                              <div className="d-flex align-items-center gap-2">
                                <input
                                    type="checkbox"
                                    id="terms2"
                                    ref={checkRef2}
                                    checked={canProceed}
                                    onChange={checkTerms}
                                />
                                <label ref={labelRef2} htmlFor="terms2">
                                  Am citit și sunt de acord cu{" "}
                                  <Link
                                      to="/terms"
                                      target={'_blank'}
                                      className="go-to-factura-link"
                                  >
                                    termenii și condițiile
                                  </Link>
                                  .
                                </label>
                              </div>
                              {prequest.Products.length ? (
                                  <FormCreate
                                      passRef={refForm}
                                      api={api}
                                      pRequest={prequest}
                                      onSubmit={openPaymentModal}
                                      // disabled={!canProceed}
                                  />
                              ) : null}
                            </>
                        ) : (
                            <div className="w-100">
                            <span className="processing-text">
                              Există o tranzacție în procesare, vă rugăm să
                              reveniți mai tîrziu
                            </span>
                            </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <div className="d-flex justify-content-center big-y-margin">
                <button
                  onClick={onProcessPayment}
                  className="reverse-ident-btn"
                  type="submit"
                >
                  Achita Online
                </button>
              </div> */}
            </div>
          ) : (
            <div onKeyDown={handleKeyDown} tabIndex={"0"}>
              <div className="d-flex align-items-center justify-content-between px-4 mb-5 insert-code-block">
                <h2 className="text-capitalize mb-0">Introduceți Codul ID</h2>
                <div className="position-relative w-25 data-input-el">
                  {error ? (
                    <input
                      type="text"
                      onClick={() => setError(false)}
                      className="code-error-input"
                      onChange={(e) => {
                      //  console.log(e);
                      }}
                      value={"Cod incorrect"}
                    />
                  ) : (
                    <input
                      type="text"
                      name="codid"
                      onChange={onChangeHandler}
                      // onKeyDown={handleKeyDown}
                      value={codeNumber}
                      className="code-input"
                    />
                  )}

                  <span className="code-span">(cod din 9 cifre)</span>
                </div>
                {/* <button onClick={() => setCodeNumber("")} className="x-btn">
                  &#215;
                </button> */}
                <button
                  onClick={getFactura}
                  className="ident-btn"
                  type="submit"
                >
                  Identificare
                </button>
              </div>
              <div className="container doc-box">
                <Image src={FacturaImg} />
              </div>
              {/* <div className="d-flex justify-content-center">
                <button
                  onClick={getFactura}
                  className="ident-btn"
                  type="submit"
                >
                  Identificare
                </button>
              </div> */}
            </div>
          )}
        </div>
      </section>
      <DialogModal
        show={show}
        handleClose={() => setShow(false)}
        handleConfirm={() => specFunc()}
        handleDecline={() => specFuncTwo()}
        yesText={yesText}
        noText={noText}
        question={question}
      />

      {showAlert && (
        <Alert className="custom-alert-style" variant="success" onClose={() => setShowAlert(false)} dismissible>
          <Alert.Heading>
            <div className="alert-icon">
              <CheckCircle />
            </div>
            <span className="alert-text">Datele au fost salvate cu succes!</span>
          </Alert.Heading>
        </Alert>
      )}
    </div>
  );
}
export default Factura;
