import CryptoJS from "crypto-js";

let host =
  process.env.REACT_APP_BASE_URL ||
  (process.env.NODE_ENV === "production"
    ? "https://profadm-api.mindscape.xyz"
    :"https://profadm-api.mindscape.xyz");

export const API_URL = `${host}/api/v1/`;

export const generateSignature = (paramsDictionary, secretKey) => {
  let signatureData = "";
  for (let key in paramsDictionary) {
    if (paramsDictionary.hasOwnProperty(key)) {
      signatureData += paramsDictionary[key];
    }
  }

  const message = signatureData + secretKey;

  const utf8Encoder = new TextEncoder();
  const bytes = utf8Encoder.encode(message);

  const hash = CryptoJS.MD5(bytes);

  const signature = hash.toString(CryptoJS.enc.Base64);
  return signature;
};
